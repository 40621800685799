import { PillarQuery } from "../fragments/NodePillarFields";
import { VideoCat } from "./VideoCat";

export class Pillar {
  title: string;
  videoCategories: VideoCat[];
  description: string;
  slug: string;
  constructor(node: PillarQuery) {
    const _node = { ...node };
    const videoCategories = Array.isArray(_node.videoCategories)
      ? _node.videoCategories.map((videoCategory) => {
          return new VideoCat(videoCategory);
        })
      : [];
    this.title = _node.title;
    this.videoCategories = videoCategories;
    this.description = _node.description;
    this.slug = _node.slug;
  }
}
