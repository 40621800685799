import { ContentfulImageQuery, Img } from "wmk-image";
import { VideoCategoryQuery } from "../fragments/NodeVideoCategoryFields";
import { Video } from "./Video";

export class VideoCat {
  title: string;
  videos: Video[];
  fullCategoryVideo: Video;
  description: string;
  thumbnail: Img;
  constructor(node: VideoCategoryQuery) {
    const _node = { ...node };
    const videos = Array.isArray(_node.videos)
      ? _node.videos.map((video) => {
          return new Video(video);
        })
      : [];
    this.title = _node.title;
    this.videos = videos;
    this.fullCategoryVideo = new Video(_node.fullCategoryVideo);
    this.description = _node.description;
    this.thumbnail = new Img(_node.thumbnail);
  }
}
