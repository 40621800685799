import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ContentfulAssetQuery, WmkImage } from "wmk-image";
// import DipraButton from "./DipraButton";
import styled from "styled-components";
import { Img } from "wmk-image";
import { colors } from "../ui/colors";
import MainButton from "../Buttons/MainButton";
import { Typography } from "../ui/Typography";
import DipraButton from "../Buttons/DipraButton";

const CtaContainer = styled(Container)`
  background: ${colors.green.getHex()};
  color: ${colors.white.getHex()} !important;
  padding-top: 2rem;
  padding-bottom: 2rem;
  span {
    color: ${colors.white.getHex()};
  }
  div {
    color: ${colors.white.getHex()} !important;
  }
  .copy-col {
    padding-right: 0%;
    padding-left: 2rem;
    max-width: 55%;
    margin-right: 10%;
  }
  .image-col {
    display: flex;
    align-items: center;
  }
  .gatsby-image-wrapper {
    width: 12rem;
  }
  .row {
    justify-content: right;
  }
  @media only screen and (max-width: 1200px) {
    .copy-col {
      padding-right: 0%;
    }
  }
  @media only screen and (max-width: 1000px) {
    .copy-col {
      padding-right: 2rem;
      margin-right: 5%;
    }
  }
  @media only screen and (max-width: 767px) {
    .copy-col {
      max-width: fit-content;
      padding-right: 0;
    }
    .gatsby-image-wrapper .gatsby-image-wrapper-constrained {
    }
    .image-col {
      justify-content: center;
    }
  }
`;

const PdfCta = () => {
  const data = useStaticQuery(pdfQuery);
  const image = new Img(data.preview);
  return (
    <CtaContainer fluid>
      <Row>
        <Col className="image-col" sm={12} md="auto">
          <WmkImage image={image} />
        </Col>
        <Col className="copy-col" sm={12} md="auto">
          <Typography.BLOCKQUOTE
            style={{
              fontSize: "24px",
              fontWeight: 500,
              textAlign: "start",
              paddingTop: "1rem"
            }}>
            Unlock Your Potential: Explore Our Online Course Brochure
          </Typography.BLOCKQUOTE>
          <Typography.P style={{ padding: "1rem 0" }}>
            We provide engineers with valuable technical information and
            real-life application examples needed to specify the correct
            materials for the planning and design of water infrastructure
            projects.{" "}
          </Typography.P>
          <DipraButton
            link={{ to: data.contentfulAsset.url, text: "Download" }}
            to={data.contentfulAsset.url}
            color={colors.darkBlue.getHex()}
            textColor={colors.white.getHex()}
            style={{
              fontSize: `.8rem`,
              transition: `all .3s ease`,
              textTransform: "uppercase"
            }}
            innerPadding="0.5rem 2rem"
            hoverColor={colors.secondary.getHex()}
            ></DipraButton>
        </Col>
      </Row>
    </CtaContainer>
  );
};

export default PdfCta;
const pdfQuery = graphql`
  {
    contentfulAsset(title: { eq: "Knowledge Pipeline Program Brochure" }) {
      __typename
      title
      mimeType
      url
      filename
      description
      timestamp: updatedAt
    }
    preview: contentfulAsset(
      title: { eq: "Knowledge-Pipeline-Brochure-Mockup 2" }
    ) {
      ...NodeImageQuery
    }
  }
`;
