import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { WmkImage } from "wmk-image";
import { WmkLink } from "wmk-link";
import { Pillar } from "../../classes/Pillar";
// import { Video } from "../../classes/Video";
import { VideoCat } from "../../classes/VideoCat";
import { colors } from "./colors";
import { Typography } from "./Typography";

const PreviewCardContainer = styled(Container)`
  margin-bottom: 2rem;
  a {
    text-decoration: none;
  }
  .color-col {
    height: 2rem;
    width: 100%;
    background: ${colors.secondary.getHex()};
  }
  .image-col {
    padding: 0;
    width: calc(100% + 4px);
  }
  .text-col {
    border: solid 2px ${colors.secondary.getHex()};
  }
`;

const getVideoCategoryMinutes = (videoCategory: VideoCat) => {
  const totalMinutes = videoCategory.videos.reduce((acc, video) => {
    acc += video.videoLengthMinutes;
    return acc;
  }, 0);
  return totalMinutes;
};

export const getVideoCategoryDetails = (videoCategory: VideoCat) => {
  const totalMinutes = getVideoCategoryMinutes(videoCategory);
  const videoNumber = videoCategory.videos.length;
  const videoQuantities = `${videoNumber} ${
    videoNumber > 1 ? `videos` : `video`
  }, ${totalMinutes} ${totalMinutes > 1 ? `minutes` : `minute`}.`;
  return videoQuantities;
};

export const getCourseDetails = (course: Pillar) => {
  const totalMinutes = course.videoCategories.reduce((acc, videoCategory) => {
    acc += getVideoCategoryMinutes(videoCategory);
    return acc;
  }, 0);
  const videoNumber = course.videoCategories.reduce((acc, videoCategory) => {
    acc += videoCategory.videos.length;
    return acc;
  }, 0);
  const videoQuantities = `${videoNumber} ${
    videoNumber > 1 ? `videos` : `video`
  } | ${totalMinutes} ${totalMinutes > 1 ? `minutes` : `minute`}`;
  return videoQuantities;
};

export const PreviewCard = ({
  data,
  locked,
  courseSlug
}: {
  data: VideoCat | Pillar;
  locked?: boolean;
  courseSlug?: string;
}) => {
  const isVideoCat = data instanceof VideoCat;

  return (
    <>
      <PreviewCardContainer fluid>
        <WmkLink
          to={
            locked
              ? "#form-header"
              : courseSlug
              ? `/pillars/${courseSlug}`
              : isVideoCat
              ? `/videos/${data.videos[0].slug}`
              : `/${data.videoCategories[0].videos[0].slug}`
          }>
          <Row>
            <Col className="color-col"></Col>
          </Row>
          <Row>
            <Col className="image-col">
              <WmkImage
                image={
                  isVideoCat
                    ? data?.thumbnail
                    : data?.videoCategories[0]?.thumbnail
                }
              />
            </Col>
          </Row>
          <Row>
            <Col className="text-col">
              <Typography.BLOCKQUOTE
                style={{
                  // fontFamily: "Montserrat",
                  textTransform: "uppercase",
                  margin: "0.25rem 0 0.75rem",
                  color: colors.primary.getHex(),
                  fontWeight: 700
                }}>
                {data.title}
              </Typography.BLOCKQUOTE>
              <Typography.BLOCKQUOTE
                style={{
                  // fontFamily: "Montserrat",
                  textTransform: "uppercase",
                  fontWeight: 500,
                  color: colors.secondary.getHex(),
                  margin: "0 0 0.5rem"
                }}>
                {isVideoCat
                  ? getVideoCategoryDetails(data)
                  : getCourseDetails(data)}
              </Typography.BLOCKQUOTE>
              <Typography.P
                style={{
                  margin: "0 0 1rem",
                  color: colors.bodyText.getHex(),
                  fontFamily: "Montserrat"
                }}>
                {data.description}
              </Typography.P>
            </Col>
          </Row>
        </WmkLink>
      </PreviewCardContainer>
    </>
  );
};
