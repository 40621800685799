import React from "react";
import { graphql } from "gatsby";
import { WmkSeo } from "wmk-seo";
import { SiteMetaDataFields } from "../fragments/NodeSiteMetadataFields";
import Gallery from "../components/Blocks/Gallery";
import { Pillar } from "../classes/Pillar";
import { PillarQuery } from "../fragments/NodePillarFields";
import { useState, useEffect } from "react";
import { Typography } from "../components/ui/Typography";
import { WmkLink } from "wmk-link";
import PdfCta from "../components/Blocks/PdfCta";


const gallery = ({
  data
}: {
  data: {
    site: SiteMetaDataFields;
    pillar: PillarQuery;
  };
}) => {
  const [redirect, setRedirect] = useState(false);
  const [validUser, setValidUser] = useState(false);

  useEffect(() => {
    if (window && localStorage?.getItem("hasCompletedForm")) {
      setValidUser(true)
    }
  },[])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (redirect) {
        window.location.href = `/`;
      }
    }, 1000);
    if (validUser) {
      clearTimeout(timer);
    }
  }, [redirect, validUser]);

  useEffect(() => {
    setTimeout(() => {
      if (!validUser) {
        setRedirect(true);
      }
    }, 1000);
  }, [validUser]);

  const currentPillar = new Pillar(data.pillar);

  return (
    <>
      <WmkSeo.Meta
        title={`${data?.pillar.title}`}
        siteTitle={data?.site?.siteMetadata?.title}
        baseUrl={data?.site?.siteMetadata?.baseUrl}
        slug={`/${data.pillar.slug}`}
        ogImageUrl=""
        twitterImageUrl=""
        twitterHandle=""
      />
      {validUser ? (
        <>
          <Gallery
            data={currentPillar.videoCategories}
            title="Topics"
            subtitle={data?.pillar?.title ? data.pillar.title : undefined}
            largeDisplay
            backButton
          />
          <PdfCta />
        </>
      ) : (
        <>
          <Typography.H3 style={{ textAlign: "center", margin: "0 10%" }}>
            Please go to the <WmkLink to="/">home page</WmkLink> and fill out
            the form to access this free content.
          </Typography.H3>
        </>
      )}
    </>
  );
};

export default gallery;

export const query = graphql`
  query ($slug: String!) {
    site {
      ...NodeSiteMetadata
    }
    pillar: contentfulPillar(slug: { eq: $slug }) {
      ...NodePillarFields
    }
  }
`;
