import { Img } from "wmk-image";
import { VideoQuery } from "../fragments/NodeVideoFields";

export class Video {
  title: string;
  slug: string;
  about: string;
  video: string | undefined;
  videoLink: string | undefined;
  preview: Img;
  videoLengthMinutes: number;
  resources: {
    title: string;
    file: {
      fileName: string;
      url: string;
      contentType: string;
    };
  }[];
  constructor(node: VideoQuery) {
    const _node = { ...node };
    const preview = new Img(_node.preview);
    this.title = _node.title;
    this.slug = _node.slug;
    this.about = _node.about?.about;
    this.video = _node.video?.file?.url;
    this.videoLink = _node.videoLink;
    this.preview = preview;
    this.videoLengthMinutes = _node.videoLengthMinutes;
    this.resources = _node.resources;
  }
}
