import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Pillar } from "../../classes/Pillar";
import { VideoCat } from "../../classes/VideoCat";
import TextButton from "../Buttons/TextButton";
import { colors } from "../ui/colors";
import { PreviewCard } from "../ui/PreviewCard";
import { Typography } from "../ui/Typography";

const GalleryContainer = styled(Container)`
  margin-top: 0rem;
  margin-bottom: 4rem;
  .back-button-col {
    a:hover {
      color: ${colors.secondary.getHex()};
      blockquote {
        color: ${colors.secondary.getHex()};
      }
    }
  }
`;

const Gallery = ({
  data,
  title,
  subtitle,
  largeDisplay,
  homePage,
  backButton
}: {
  data: (VideoCat | Pillar)[];
  title?: string;
  subtitle?: string;
  largeDisplay?: boolean;
  homePage?: boolean;
  backButton?: boolean;
}) => {
  const [viewWidth, setViewWidth] = useState(0);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    const winWidth = window.innerWidth;
    setViewWidth(winWidth);
  };

  // const data: { cards: { edges: { node: VideoCategoryQuery }[] } } =
  //   useStaticQuery(query);

  // const videoCats = data.edges.map((card) => {
  //   return new VideoCat(card.node);
  // });

  return (
    <GalleryContainer>
      {backButton && (
        <Row>
          <Col className="back-button-col">
            <TextButton to="/pillars">View All Pillars</TextButton>
          </Col>
        </Row>
      )}
      {title && (viewWidth < 991 || largeDisplay) && (
        <Row>
          <Col>
            <Typography.H2
              style={{
                // fontFamily: "Montserrat",
                textAlign: "center",
                fontWeight: 400,
                margin: "0 0 5rem"
              }}>
              {title}
            </Typography.H2>
          </Col>
        </Row>
      )}
      {subtitle && (
        <Row>
          <Col>
            <Typography.BLOCKQUOTE
              style={{
                fontWeight: 700,
                textTransform: "uppercase",
                color: colors.secondary.getHex(),
                paddingLeft: "calc(var(--bs-gutter-x) * 0.5)"
              }}>
              {subtitle}
            </Typography.BLOCKQUOTE>
          </Col>
        </Row>
      )}
      <Row>
        {data.map((videoCat, i) => {
          // console.log("videoCat", videoCat);
          return (
            <Col lg={4} md={6} sm={6} key={`${videoCat.title} ${i}`}>
              <PreviewCard
                data={videoCat}
                locked={homePage}
                courseSlug={
                  !(videoCat instanceof VideoCat) ? videoCat.slug : undefined
                }
              />
            </Col>
          );
        })}
      </Row>
    </GalleryContainer>
  );
};

export default Gallery;
