import React from "react";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
// import { HyperLink } from "../../classes/HyperLink";
import CSS from "csstype";
// import { ButtonLink } from "../../classes/ButtonLink";
import { colors } from "../ui/colors";

const ButtonContainer = styled.div<{
  invisible: boolean;
  color: string;
  textColor: string;
  rounded: boolean;
  fluid: boolean;
  fontSize: string;
  bold: boolean;
  hoverColor: string;
  innerPadding: string;
  height: string;
  style: CSS.Properties;
}>`
  max-width: 100%;
  align-items: center;
  border-bottom: ${(props) =>
    props.invisible ? `6px solid transparent` : "none"};
  background-color: ${(props) =>
    props.color ? props.color : colors.white.getHex()};
  border-radius: ${(props) => (props.rounded ? "10px" : "unset")};
  box-sizing: border-box;
  color: ${(props) =>
    props.textColor ? props.textColor : colors.darkBlue.getHex()};
  cursor: pointer;
  display: ${(props) => (props.fluid ? "flex" : "inline-flex")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
  font-weight: ${(props) => (props.bold ? 700 : 400)};
  &:hover {
    background-color: ${(props) =>
      props.hoverColor ? props.hoverColor : colors.darkBlue.getHex()};
    border-bottom: ${(props) =>
      props.invisible ? `6px solid ${colors.green.getHex()}` : "none"};
    color: ${(props) =>
      props.invisible ? colors.black.getHex() : colors.white.getHex()};
  }

  &:active {
    background-color: ${(props) =>
      props.hoverColor ? props.hoverColor : colors.darkBlue.getHex()};
    border-bottom: ${(props) =>
      props.invisible ? `6px solid ${colors.green.getHex()}` : "none"};
    color: ${(props) =>
      props.invisible ? colors.black.getHex() : colors.white.getHex()};
  }

  a {
    align-items: center;
    color: unset;
    display: ${(props) => (props.fluid ? "block" : "contents")};
    display: flex;
    height: 100%;
    justify-content: center;
    padding: ${(props) =>
      props.innerPadding ? props.innerPadding : "0.25rem 2rem"};
    text-decoration: none;
    width: 100%;

    &:hover {
      text-decoration: none;
    }
  }
`;

/**
 *
 */

export interface DipraButtonProps {
  fluid?: boolean;
  invisible?: boolean;
  rounded?: boolean;
  bold?: boolean;
  link: HyperLink | ButtonLink;
  color?: string;
  fontSize?: string;
  height?: string;
  hoverColor?: string;
  innerPadding?: string;
  textColor?: string;
  style?: CSS.Properties;
}

const DipraButton = ({
  color,
  fluid,
  fontSize,
  bold,
  height,
  hoverColor,
  innerPadding,
  invisible,
  link,
  rounded,
  textColor,
  style
}: DipraButtonProps) => {
  const { target, text, to, rootPath } = link || {};

  const fullPath = to
  return (
    <ButtonContainer
      color={color}
      fluid={fluid}
      fontSize={fontSize}
      bold={bold}
      height={height}
      hoverColor={hoverColor}
      innerPadding={innerPadding}
      invisible={invisible}
      rounded={rounded}
      textColor={textColor}
      style={style}>
      <WmkLink to={fullPath} target={target}>
        {text}
      </WmkLink>
    </ButtonContainer>
  );
};

export default DipraButton;
